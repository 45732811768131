import React from 'react';
import './Pricing.css';
import { useNavigate } from 'react-router-dom';

const pricingData = [
  {
    title: 'Data Analytics',
    path: '/services/data-analytics'
  },
  {
    title: 'Property Management',
    path: '/services/property-management'
  },
  {
    title: 'Project Management',
    path: '/services/project-management'
  },
  {
    title: 'Consulting Services',
    path: '/services/consulting-services'
  },
  {
    title: 'Website Development',
    path: '/services/website-development'
  },
  {
    title: 'Mobile App Development',
    path: '/services/mobile-app-development'
  }
];

const handleEmailButtonClick = () => {
  window.location.href = 'mailto:codelux.outreach@gmail.com?subject=Small%20Business%20Inquiry';
};

const Pricing = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="pricing-container">
      <div className="pricing-section">
        <h1 className="pricing-heading">Pricing</h1>
        <h3 className="pricing-description">
          Pricing is determined by the scope of the service provided, the total estimated time, and the category of service. Below is a list of our service lines. Click on an item to view its respective page.
        </h3>
        <div className="pricing-buttons">
          {pricingData.map((item, index) => (
            <button key={index} className="pricing-button" onClick={() => navigateTo(item.path)}>
              {item.title}
            </button>
          ))}
        </div>
      </div>
      <div className="small-business-section">
        <h1 className="small-business-heading">Small Business Pricing</h1>
        <h3 className="small-business-description">
          We understand that small businesses cannot always afford customized software. Our pricing is designed to be flexible and accommodating to fit various budgets. We work closely with you to tailor our services to meet your needs and provide the best value for your investment. Whether you're looking to start a new project or enhance an existing one, we're here to help you achieve your goals without breaking the bank.
        </h3>
        <h3 className="small-business-description">
          If you are a small business looking for software solutions, contact us to see how we can work with your business and your budget.
        </h3>

        <div className='cta'>
          <button onClick={handleEmailButtonClick}>Contact Us</button>
      </div>
      </div>
    </div>
  );
};

export default Pricing;
