import React from 'react';
import '../Cards.css';
import { SectionButton } from '../SectionButton';
import { Button } from '../Button';
import { FaBuilding } from 'react-icons/fa';

function PropertyManagement({ formRef, setServiceRequest }) { 

  const handleCardClick = (requestText) => {
    if (formRef.current) {
      setServiceRequest(requestText); 
      const topOffset = formRef.current.offsetTop - 100; 
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='cards-property'>
      <div className='cards__title'>
        <FaBuilding style={{color: 'black', fontSize: 30, marginRight: -15}} />
        <h1 style={{ color: 'black' }}>Property Management Software Solutions</h1>
      </div>
      <div style={{ width: '100%', padding: 20, marginBottom: 30, alignItems: 'center' }}>
        <p style={{ marginBottom: 10, borderRadius: 8, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
           We develop custom software solutions designed to streamline property management operations. 
           Our tools help you manage tenants, optimize property maintenance, and simplify financial processes, 
           allowing you to focus on growth and improving asset performance.
        </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items' style={{ textAlign: 'left', color: 'silver', fontSize: 18, lineHeight: 1.6 }}>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Automate tenant management with custom software.</strong> 
              {/* Our CRM systems handle tenant inquiries, lease management, and communication, ensuring a smooth experience for property managers and tenants alike. */}
            </li>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Efficiently schedule and track property maintenance with intuitive dashboards.</strong> 
              {/* Our software allows you to automate maintenance requests and track vendor performance, ensuring your properties are well-maintained with minimal effort. */}
            </li>
            <li style={{ color: 'black', marginRight: '20px' }}>
              <strong>Streamline financial management and reporting.</strong> 
              {/* Our custom solutions make rent collection, budgeting, and financial reporting more efficient, giving you real-time insights into your property's performance. */}
            </li>
          </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Button
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleViewSalesClick}
        >
          - Schedule An Appointment -
        </Button>
      </div>
    </div>
  );
}

export default PropertyManagement;
