import React from 'react';

const buttonStyle = {
  width: '220px',
  height: '220px',
  paddingTop: 20,
  background: '#f7f7f7',
  borderRadius: '120px',
  border: '3px solid #b49500', 
  color: '#ffffff',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center',
  lineHeight: '100px',
  transition: 'background 0.3s ease',
  boxShadow: '6px 6px 2px rgba(0, 0, 0, 0.7)',
  marginRight: 10,
  marginLeft: 10,
};

export const ServicesButton = ({
  children,
  type,
  onClick,
}) => {
  return (
    <div style={{ marginTop: 40 }}>
      <button
        style={buttonStyle}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </div>
  );
};
