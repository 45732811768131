import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const dataAnalyticsSections = [
  {
    image: '../../../images/img-mobileApp1.png',
    text: "Mobile apps can help bring your business to the forefront of user's attention. CodeLux has produced over 30 apps to both of the major app stores on iOS and Android and can help you with your design."
  },
  {
    image: '../../../images/img-mobileApp2.png',
    text: "We offer mobile app development services to bring your ideas to life on iOS and Android platforms. We specialize in creating user-friendly apps that follow your designs, meet your business needs, and delight your users. Whether you need an app for customer engagement, internal operations, or service delivery, we provide the expertise and support to ensure your app's success. Partner with us to below."
  }
];

const MobileApps = () => {
  return <ServiceTemplate serviceTitle="Mobile Applications" sections={dataAnalyticsSections} />;
};

export default MobileApps;