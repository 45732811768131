import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Pricing from './components/pages/Pricing';
import AboutUs from './components/pages/AboutUs';
import Mission from './components/pages/Mission';
import Articles from './components/pages/Articles';
import DataAnalytics from './components/pages/DataAnalytics';
import Consulting from './components/pages/Consulting';
import MobileApps from './components/pages/MobileApps';
import ProjectManagement from './components/pages/ProjectManagement';
import PropertyManagement from './components/pages/PropertyManagement';
import Website from './components/pages/Website';
import EnergyScreen from './components/pages/EnergyScreen';
import Construction from './components/pages/Construction';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={ <Home />} />
          <Route path='/services' exact element={ <Services />} />
          <Route path='/pricing' exact element={ <Pricing />} />
          <Route path='/about-us' exact element={ <AboutUs />} />
          <Route path='/our-mission' exact element={ <Mission />} />
          <Route path='/articles' exact element={ <Articles />} />
          <Route path='/services/data-analytics' exact element={ <DataAnalytics />} />
          <Route path='/services/property-management' exact element={ <PropertyManagement />} />
          <Route path='/services/project-management' exact element={ <ProjectManagement />} />
          <Route path='/services/consulting' exact element={ <Consulting />} />
          <Route path='/services/construction' exact element={ <Construction />} />
          <Route path='/services/energy-services' exact element={ <EnergyScreen />} />
          <Route path='/services/website' exact element={ <Website />} />
          <Route path='/services/mobile-apps' exact element={ <MobileApps />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;