import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const dataAnalyticsSections = [
  {
    image: '../../../images/img-websiteDev.png',
    text: "Websites and website maintenance is a central need for all existing and emerging businesses. Websites have been shown to increase a company's credibility, grow its online presence, and help contribute to market expansion."
  },
  {
    image: '../../../images/img-websiteDev2.jpg',
    text: 'CodeLux offers website development as a service to help you create a strong online presence for your business. We are happy to work with your designs and tailor any site to your brand and objectives. Whether you need a simple informational site or a complex web application, we look forward to helping bring your vision to life. Partner with us to build a website that engages your audience, enhances your brand, and drives your business growth.'
  }
];

const Website = () => {
  return <ServiceTemplate serviceTitle="Website Services" sections={dataAnalyticsSections} />;
};

export default Website;