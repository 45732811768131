import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const constructionSections = [
  {
    image: '../../../images/img-construction1.png',
    text: 'Our software solutions for the construction industry help streamline project management, optimize resource allocation, and enhance collaboration. From developing custom project tracking tools to integrating real-time communication systems, our services are designed to meet the unique needs of construction professionals.'
  },
  {
    image: '../../../images/img-construction2.png',
    text: 'We provide tailored software development services to address the complexities of construction projects. Our solutions include project management software, cost estimation tools, and safety compliance systems. Whether you need advanced analytics, automation, or mobile access, our team will develop customized software to support your operational goals and improve overall efficiency.'
  }
];

const Construction = () => {
  return <ServiceTemplate serviceTitle="Construction Software Solutions" sections={constructionSections} />;
};

export default Construction;
