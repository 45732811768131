import React from 'react';
import '../Cards.css';
import { Button } from '../Button';
import { FaFire } from 'react-icons/fa';

function EnergyServices({ formRef, setServiceRequest }) { 

  const handleCardClick = (requestText) => {
    if (formRef.current) {
      setServiceRequest(requestText); 
      const topOffset = formRef.current.offsetTop - 100; 
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='cards-energy'>
      <div className='cards__title'>
        <FaFire style={{color: 'black', fontSize: 30, marginRight: -15}} />
        <h1 style={{ color: 'black' }}>Energy Software Solutions</h1>
      </div>
      <div style={{ width: '100%', padding: 20, marginBottom: 30, alignItems: 'center' }}>
        <p style={{ marginBottom: 10, borderRadius: 8, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
           We specialize in developing custom software solutions tailored for the energy industry. 
           Our goal is to empower energy companies with cutting-edge technology, streamlining operations 
           and improving efficiency while navigating the complexities of energy management.
        </p>
        <p style={{ marginBottom: 10, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
           Partner with us to create innovative, scalable software that addresses the unique challenges of your energy business.
        </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items' style={{ textAlign: 'left', color: 'silver', fontSize: 18, lineHeight: 1.6 }}>
              <li style={{ color: 'black', marginRight: '30px' }}>
                <strong>Develop custom software to optimize energy resource management.</strong> 
                {/* Our software solutions help you track and manage energy usage, monitor resources in real-time, and reduce operational costs through smart analytics and automation. */}
              </li>
              <li style={{ color: 'black', marginRight: '30px' }}>
                <strong>Implement AI-driven solutions for predictive maintenance.</strong> 
                {/* We leverage machine learning and AI technologies to predict equipment failures and optimize maintenance schedules, reducing downtime and enhancing system reliability. */}
              </li>
              <li style={{ color: 'black', marginRight: '20px' }}>
                <strong>Automate energy trading and risk management processes.</strong> 
                {/* Our custom platforms provide automation for energy trading, helping your team handle real-time transactions, forecast market trends, and mitigate risks. */}
              </li>
            </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Button
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleViewSalesClick}
        >
          - Schedule An Appointment -
        </Button>
      </div>
    </div>
  );
}

export default EnergyServices;
