import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const dataAnalyticsSections = [
  {
    image: '../../../images/img-projectMgmt1.png',
    text: 'Project management can often be improved by focusing on clear communication, key objectives and timelines, and reducing potential delays before they occur.'
  },
  {
    image: '../../../images/img-projectMgmt2.png',
    text: "Our Project Management services are designed to help you effectively plan, execute, and complete projects on time and within budget. We offer a range of tools for task scheduling, resource allocation, and progress tracking, all customizable to suit your project's unique demands. Whether you need assistance with project planning, risk management, or performance monitoring, we provide the expertise and support to ensure your project's success. Partner with us to streamline your workflows and better meet your project goals."
  }
];

const ProjectManagement = () => {
  return <ServiceTemplate serviceTitle="Project Management" sections={dataAnalyticsSections} />;
};

export default ProjectManagement;