import React, { useState } from 'react';
import './ServiceTemplate.css';
import emailjs from 'emailjs-com';

const ServiceTemplate = ({ serviceTitle, sections }) => {
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [serviceRequest, setServiceRequest] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serviceID = 'service_v8n3mhm';
    const templateID = 'template_gzgkg1s';
    const userID = '9zZu49SvWf8jbg-cZ';

    const templateParams = {
      name,
      companyName,
      email,
      serviceRequest,
    };

    try {
      await emailjs.send(serviceID, templateID, templateParams, userID);
      setSubmitted(true);
      setSubmitted(true);
      setName('');
      setCompanyName('');
      setEmail('');
      setServiceRequest('');
    } catch (error) {
      // console.error('Email sending failed:', error);
      // Handle error state or alert the user
    }

    const form = e.target;
    form.reset(); // Reset form fields
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'serviceRequest':
        setServiceRequest(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="service-container">
      <h1>{serviceTitle}</h1>
      {sections.map((section, index) => (
        <div
          key={index}
          className={`section ${index % 2 === 0 ? 'left' : 'right'}`}
        >
          <img src={section.image} alt={`Section ${index + 1}`} />
          <div className="text-container">
            <p>{section.text}</p>
            {section.button && (
              <div className="button-container">
                <button 
                  onClick={() => window.location.href = section.button.url}>
                    {section.button.label}</button>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="sign-up-form">
        <h2>Contact Us</h2>
        <h3>Let us know how we can help. Submit an inquiry to get started.</h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name*</label>
            <input
              type="text"
              name='name'
              value={name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Company Name*</label>
            <input
              type="text"
              name='companyName'
              value={companyName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Email*</label>
            <input
              type="email"
              name='email'
              value={email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Inquiry*</label>
            <textarea
              name='serviceRequest'
              value={serviceRequest}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
        {submitted && <p style={{ color: 'green', marginTop: '10px' }}>serviceRequest submitted successfully!</p>}
      </div>
    </div>
  );
};

export default ServiceTemplate;
