import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const energySections = [
  {
    image: '../../../images/img-energy1.png',
    text: 'Our software solutions for the energy sector are designed to optimize resource management, enhance operational efficiency, and support data-driven decision-making. We offer services ranging from developing custom management dashboards and softwares to integrating AI for predictive maintenance.'
  },
  {
    image: '../../../images/img-energy2.png',
    text: 'We specialize in creating software solutions that address the unique challenges of the energy industry. Our services include developing platforms for data visualization, data analytics, and more. Partner with us to leverage our expertise in software development to drive innovation and improve performance in your energy operations.'
  }
];

const EnergyScreen = () => {
  return <ServiceTemplate serviceTitle="Energy Services" sections={energySections} />;
};

export default EnergyScreen;
