import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  const handleClick = (e) => {
    if (props.externalLink) {
      window.location.href = props.externalLink;
    } else if (props.onClick) {
      props.onClick();
    } else if (!props.path) {
      e.preventDefault(); // Prevent default behavior if path is not provided
    }
  };

  const renderLink = () => {
    // For all cards that send you to an external site
    if (props.externalLink) {
      return (
        <a className='cards__item__link' href={props.externalLink} onClick={handleClick}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img className='cards__item__img' alt='Service' src={props.src} />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            {/* <p className='cards__item__more-info'>Details</p> */}
          </div>
        </a>
      );
      // All cards internal path
    } else if (props.path) {
      return (
        <Link className='cards__item__link' to={props.path} onClick={handleClick}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img className='cards__item__img' alt='Service' src={props.src} />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            {/* <p className='cards__item__more-info'>Details</p> */}
          </div>
        </Link>
      );
    } else if (props.onClick) {
      // For the Consulting services card
      return (
        <a className='cards__item__link' href={props.externalLink} onClick={handleClick}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img className='cards__item__img' alt='Service' src={props.src} />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            {/* <p className='cards__item__more-info'>Details</p> */}
          </div>
        </a>
      );
    } else {
      // No path
      return (
        <div className='cards__item__link' onClick={handleClick}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img className='cards__item__img' alt='Service' src={props.src} />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <p className='cards__item__more-info'>Coming Soon</p>
          </div>
        </div>
      );
    }
  };

  return (
    <li className='cards__item'>
      {renderLink()}
    </li>
  );
}

export default CardItem;
