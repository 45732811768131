import React from 'react';
import '../Cards.css';
import { Button } from '../Button';
import { FaChartBar } from 'react-icons/fa';

function DataAnalytics({ formRef, setServiceRequest }) { 

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='cards-data-analytics'>
      <div className='cards__title'>
        <FaChartBar style={{color: 'black', fontSize: 30, marginRight: -15}} />
        <h1 style={{ color: 'black' }}>Data Analytics</h1>
      </div>
      <div style={{ width: '100%', padding: 20, marginBottom: 30, alignItems: 'center' }}>
        <p style={{ marginBottom: 10, borderRadius: 8, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
          Our Data Analytics Solutions are designed to transform complex data into actionable insights, enabling informed decisions that drive your business forward. We provide the tools and support needed to optimize operations, understand market trends, and improve customer experiences.
        </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items' style={{ textAlign: 'left', color: 'silver', fontSize: 18, lineHeight: 1.6 }}>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Capture and display important metrics with customized dashboards.</strong> 
              {/* Create real-time, interactive dashboards for tracking and visualizing key business metrics. */}
            </li>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Leverage historical data to predict future trends and outcomes.</strong> 
              {/* Our predictive analytics services help you anticipate changes in the market, optimize operations, and make informed business decisions with confidence. */}
            </li>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Streamline your data collection process by automating the integration of data from multiple sources.</strong> 
              {/* Our automated reporting tools ensure that your team has access to real-time insights without the manual hassle, improving efficiency and accuracy. */}
            </li>
          </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Button
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleViewSalesClick}
        >
          - Schedule An Appointment -
        </Button>
      </div>
    </div>
  );
}

export default DataAnalytics;
