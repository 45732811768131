import React from 'react';
import '../Cards.css';
import { SectionButton } from '../SectionButton';
import { Button } from '../Button';
import { FaClipboardList } from 'react-icons/fa';

function ProjectManagement({ formRef, setServiceRequest }) { 

  const handleCardClick = (requestText) => {
    if (formRef.current) {
      setServiceRequest(requestText); 
      const topOffset = formRef.current.offsetTop - 100; 
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='cards-project'>
      <div className='cards__title'>
        <FaClipboardList style={{color: 'black', fontSize: 30, marginRight: -15}} />
        <h1 style={{ color: 'black' }}>Project Management</h1>
      </div>
      <div style={{ width: '100%', padding: 20, marginBottom: 30, alignItems: 'center' }}>
        <p style={{ marginBottom: 10, borderRadius: 8, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
        Our Project Management Services provide a structured approach to ensure projects are completed on time and within budget, helping you achieve your objectives. With our experienced team, we support you in navigating challenges, coordinating resources, and driving projects to successful completion.
        </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items' style={{ textAlign: 'left', color: 'silver', fontSize: 18, lineHeight: 1.6 }}>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Introduce Agile methodologies for flexible and collaborative workflows.</strong> 
              {/* We assist your team in adopting Agile practices, from daily stand-ups to iterative development cycles. This approach enhances responsiveness to changing project needs and improves overall team efficiency. */}
            </li>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Comprehensive management of all project phases.</strong> 
              {/* From initiation to closure, our project managers oversee every aspect of your project, ensuring timelines are met, budgets are adhered to, and project goals are successfully achieved. */}
            </li>
            <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Identify and mitigate project risks.</strong> 
              {/* Our team conducts thorough risk assessments to pinpoint potential issues. We then develop detailed mitigation plans that help prevent project disruptions and ensure smoother execution. */}
            </li>
          </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Button
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleViewSalesClick}
        >
          - Schedule An Appointment -
        </Button>
      </div>
    </div>
  );
}

export default ProjectManagement;
