import React from 'react';
import '../Cards.css';
import { Button } from '../Button';
import { FaComments } from 'react-icons/fa';

function ConsultingServices({ formRef, setServiceRequest }) { 

  const handleCardClick = (requestText) => {
    if (formRef.current) {
      setServiceRequest(requestText); 
      const topOffset = formRef.current.offsetTop - 100; 
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='cards-consulting'>
      <div className='cards__title'>
        <FaComments style={{color: 'black', fontSize: 30, marginRight: -15}} />
        <h1 style={{ color: 'black' }}>Consulting Services</h1>
      </div>
      <div style={{ width: '100%', padding: 20, marginBottom: 30, alignItems: 'center' }}>
        <p style={{ marginBottom: 10, borderRadius: 8, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
          Our Consulting Services offer tailored solutions and actionable recommendations to address your specific business needs, helping you optimize operations, drive growth, and innovate strategy. Partner with us to leverage expert guidance and unlock new opportunities for your business.
        </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items' style={{ textAlign: 'left', color: 'silver', fontSize: 18, lineHeight: 1.6 }}>
          <li style={{ color: 'black', marginRight: '30px' }}>
              <strong>Capture and display important metrics with customized dashboards.</strong> 
              {/* Create real-time, interactive dashboards for tracking and visualizing key business metrics. */}
            </li>
            <li style={{ color: 'black', marginRight: '30px' }}>
            <strong>Optimize processes and reduce costs:</strong> 
            {/* Our consultants will conduct a thorough analysis of your operations, identifying bottlenecks and areas of improvement. We then recommend practical solutions that enhance productivity and cost-effectiveness. */}
            </li>
            <li style={{ color: 'black', marginRight: '30px' }}>
            <strong>Ensure smooth transitions during organizational changes:</strong> 
            {/* Receive expert guidance on how to manage change effectively. We help you develop communication strategies, train employees, and align stakeholders to minimize resistance and ensure successful transitions. */}
            </li>
          </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Button
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleViewSalesClick}
        >
          - Schedule An Appointment -
        </Button>
      </div>
    </div>
  );
}

export default ConsultingServices;
