import React from 'react';
import '../../App.css';
import Footer from '../Footer';

function Mission() {

  const handleEmailButtonClick = () => {
    window.location.href = 'mailto:codelux.outreach@gmail.com?subject=New%20Client%20Request';
  };

  return (
    <>
       <div className='about-us'>
      <div className='header'>
        <h1>Our Mission</h1>
      </div>
      <div className='body'>
        <div className='content'>
          <p>CodeLux collaborates with ultimate transparency to deliver rock-solid software architecture that optimizes daily business protocols.</p>
          <p>With a transparent approach and a commitment to collaborative excellence, we fuse contemporary design with foolproof methods to create software that saves you time and reduces headaches. </p>
          <p>As an authority in our field, we consistently deliver innovative and modern solutions, building a foundational structure that ensures every project is both secure and reliable.</p>
          <p>Our mission is to work cooperatively with our clients, maintaining transparency and trust at every step, to bring their vision to life with unmatched consistency and precision.</p>
          <p>At CodeLux, we turn code into masterpieces.</p>
          <p></p>
        </div>
      </div>
      <div className='cta'>
          <button onClick={handleEmailButtonClick}>Contact Us</button>
      </div>
    </div>
      <Footer />
    </>
  );
}

export default Mission;