import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const dataAnalyticsSections = [
  {
    image: '../../../images/img-dataAnalytics1.png',
    text: 'Data analytics converts raw data into actionable insights. It includes a range of tools, technologies, and processes used to find trends and solve problems by using data. Data analytics can shape business processes, improve decision-making, and foster business growth. ',
  },
  {
    image: '../../../images/img-dataAnalytics2.png',
    text: 'Our Data Analytics services offer comprehensive solutions designed to drive your business forward. We specialize in data visualization and are happy to work with your designs and needs. Whether you need software to track performance metrics, or a dashboard designed to show relevant metrics, we provide support to meet your requirements. Partner with us to transform your operations, uncover valuable insights, and make more informed decisions.'
  }
];

const DataAnalytics = () => {
  return <ServiceTemplate serviceTitle="Data Analytics" sections={dataAnalyticsSections} />;
};

export default DataAnalytics;