import React from 'react';
import './Marquee.css'; // Add styles for marquee animation

const Marquee: React.FC = () => {
  return (
    <div className='marquee-container'>
      <div className='marquee'>
        <img src={`${process.env.PUBLIC_URL}/images/img-momentum.png`} alt="Momentum Multifamily" className='company-logo' />
        {/* <h3>Momentum Multifamily</h3> */}

        <img src={`${process.env.PUBLIC_URL}/images/img-sayan.png`} alt="Sayan Construction" className='company-logo' />
        {/* <h3>Sayan Construction</h3> */}

        <img src={`${process.env.PUBLIC_URL}/images/img-daniels.png`} alt="Daniels & Co." className='company-logo' />
        {/* <h3>Daniels & Co.</h3> */}

        <img src={`${process.env.PUBLIC_URL}/images/img-dxt.png`} alt="DxT LLC" className='company-logo' />
        {/* <h3>DxT LLC</h3> */}

        {/* Add more companies as needed */}
      </div>
    </div>
  );
};

export default Marquee;
