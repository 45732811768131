import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const dataAnalyticsSections = [
  {
    image: '../../../images/img-consulting1.png',
    text: 'Whether you are looking for guidance for software implementation or development insight, consulting services can help to provide an objective lens into how you utilize your software, promote productivity, and meet KPIs.'
  },
  {
    image: '../../../images/img-consulting2.png',
    text: 'Our Consulting Services provide expert guidance to help you navigate complex business challenges and achieve your strategic objectives. We offer tailored consulting in areas such as business strategy, process improvement, and organizational development. Whether you need assistance with market analysis, operational efficiency, or change management, we provide customized solutions to meet your specific needs. Partner with us to leverage our expertise, gain fresh perspectives, and drive your business forward.'
  }
];

const Consulting = () => {
  return <ServiceTemplate serviceTitle="Consulting Services" sections={dataAnalyticsSections} />;
};

export default Consulting;