import React from 'react';
import ServiceTemplate from '../ServiceTemplate';

const dataAnalyticsSections = [
  {
    image: '../../../images/img-propertyMgmt1.png',
    text: 'Managing properties and overall portfolios can often be helped by adding software designed to make your position clearer. This in turn can help property owners provider greater insight to investors as to where properties and portfolios stand. CodeLux has worked with multi families to help design and create softwares that can provide clarity around where properties stand. From custom dashboards to mobile apps, CodeLux can work with you to expand your visual data support.'
  },
  {
    image: '../../../images/img-entrataDashboard.png',
    text: 'For those who utilize Entrata as part of your property management software, we offer a custom built dashboard solution to help you visualize your portfolio and individual property data. To learn more, click the button below.',
    button: {
      label: 'View Dashboard',
      url: 'https://dxtapps.com/entrata/'
    }
  },
  {
    image: '../../../images/img-propertyMgmt2.png',
    text: 'Our Property Management services provide end-to-end solutions to simplify and optimize the management of your real estate assets. We offer comprehensive tools for tenant management, lease tracking, and maintenance scheduling, tailored to your specific needs. Whether you require software to streamline operations or detailed financial reporting, we are here to support your property management requirements. Partner with us to enhance your property management efficiency, maximize tenant satisfaction, and ensure seamless operations.'
  }
];

const PropertyManagement = () => {
  return <ServiceTemplate serviceTitle="Property Management" sections={dataAnalyticsSections} />;
};

export default PropertyManagement;
