import React from 'react';
import '../../App.css';
import Cards from '../Cards';

export default function Services() {
  return (
    <>
      <h1 className='services'>Visualization and monitoring solutions</h1>
      <Cards />
    </>
  )
  
  
}