import React from 'react';
import '../Cards.css';
import { Button } from '../Button';
import { FaLaptopCode } from 'react-icons/fa';

function SmallBusiness({ formRef, setServiceRequest }) { 

  const handleCardClick = (requestText) => {
    if (formRef.current) {
      setServiceRequest(requestText); 
      const topOffset = formRef.current.offsetTop - 100; 
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='cards-small-business'>
      <div className='cards__title'>
        <FaLaptopCode style={{color: 'black', fontSize: 30, marginRight: -15}} />
        <h1 style={{ color: 'black' }}>Our Small Business Initiative</h1>
      </div>
      <div style={{ width: '100%', padding: 20, marginBottom: 30, alignItems: 'center' }}>
        <p style={{ marginBottom: 10, borderRadius: 8, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
           We understand the challenges faced by small businesses and are committed to helping you thrive. 
           That is why we designed the Small Business Backing Initiative as a way to help small businesses 
           get up to speed without breaking the bank. We are happy to work with your budget to help you build out your perfect software.
         </p>
         <p style={{ marginBottom: 10, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
           Let us partner with you to achieve your business goals.
         </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items' style={{ textAlign: 'left', color: 'silver', fontSize: 18, lineHeight: 1.6 }}>
              <li style={{ color: 'black', marginRight: '30px' }}>
                <strong>Build a responsive, user-friendly website tailored to your brand.</strong> 
                {/* Our developers work closely with you to design a website that reflects your brand and meets your business goals, from online stores to lead generation sites. We ensure your site is SEO-optimized and mobile-friendly. */}
              </li>
              <li style={{ color: 'black', marginRight: '30px' }}>
                <strong>Boost your online presence with targeted digital marketing.</strong> 
                {/* We craft a comprehensive digital marketing plan, including SEO, PPC campaigns, social media management, and email marketing. Our goal is to drive traffic, increase engagement, and convert leads into customers. */}
              </li>
              <li style={{ color: 'black', marginRight: '20px' }}>
                <strong>Manage your business finances with expert budgeting.</strong> 
                {/* Our financial consultants will help you create detailed budgets, manage cash flow, and forecast future expenses. We provide tools and advice to keep your finances on track and ensure long-term stability. */}
              </li>
            </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Button
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleViewSalesClick}
        >
          - Schedule An Appointment -
        </Button>
      </div>
    </div>
  );
}

export default SmallBusiness;