import React from 'react';
import '../Cards.css';
import { SectionButton } from '../SectionButton';
import { Button } from '../Button';
import { MdConstruction } from "react-icons/md";

function ConstructionServices({ formRef, setServiceRequest }) { 

  const handleCardClick = (requestText) => {
    if (formRef.current) {
      setServiceRequest(requestText); 
      const topOffset = formRef.current.offsetTop - 100; 
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='cards-construction'>
      <div className='cards__title'>
        <MdConstruction style={{color: 'black', fontSize: 30, marginRight: -15}} />
        <h1 style={{ color: 'black' }}>Construction Services</h1>
      </div>
      <div style={{ width: '100%', padding: 20, marginBottom: 30, alignItems: 'center' }}>
        <p style={{ marginBottom: 10, borderRadius: 8, color: 'black', textAlign: 'left', fontSize: 22, fontWeight: '500', lineHeight: 1.5 }}>
          Effective project management can determine success or failure. Our Project Management Services provide a structured approach to ensure timely, budget-conscious project completion, with experienced support to navigate challenges and achieve objectives.
        </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items' style={{ textAlign: 'left', color: 'silver', fontSize: 18, lineHeight: 1.6 }}>
              <li style={{ color: 'black', marginRight: '30px' }}>
                <strong>Accurate cost estimates for your projects. </strong> 
                {/* Our estimators analyze project scope and materials to provide precise cost estimates, helping you stay within budget. We also assist with preparing competitive bid packages that increase your chances of winning contracts. */}
              </li>
              <li style={{ color: 'black', marginRight: '30px' }}>
                <strong>Ensure safe and efficient site operations.</strong> 
                {/* We offer site supervision services to monitor project progress, enforce safety protocols, and ensure compliance with regulations. Regular audits help prevent accidents and keep your construction site running smoothly. */}
              </li>
              <li style={{ color: 'black', marginRight: '20px' }}>
                <strong>Implement eco-friendly construction practices.</strong> 
                {/* Our team specializes in sustainable construction, using energy-efficient designs and environmentally friendly materials. We help you meet green building standards while reducing your project's environmental impact. */}
              </li>
            </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Button
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleViewSalesClick}
        >
          - Schedule An Appointment -
        </Button>
      </div>
    </div>
  );
}

export default ConstructionServices;