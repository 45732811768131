import React, { useRef, useState } from 'react';
import '../App.css';
import { Button } from './Button';
import { ServicesButton } from './ServicesButton';
import './HeroSection.css';
import { useNavigate } from 'react-router-dom';
import Cards from './Cards';
import SmallBusiness from './homeSections/SmallBusiness';
import EnergyServices from './homeSections/Energy';
import DataAnalytics from './homeSections/DataAnalytics';
import ConsultingServices from './homeSections/ConsultingServices';
import ProjectManagement from './homeSections/ProjectManagement';
import ConstructionServices from './homeSections/ConstructionServices';
import PropertyManagement from './homeSections/PropertyManagement';
import { FaChartBar, FaComments, FaBuilding, FaClipboardList, FaFire } from 'react-icons/fa';
import { MdConstruction } from "react-icons/md";
import Marquee from './homeSections/Marquee.tsx';

function HeroSection() {
  const navigate = useNavigate();
  const servicesRef = useRef(null);
  const formRef = useRef(null);
  const [serviceRequest, setServiceRequest] = useState('');

  const handleViewServicesClick = () => {
    if (servicesRef.current) {
      const topOffset = servicesRef.current.offsetTop - 50;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleViewSalesClick = () => {
    if (formRef.current) {
      const topOffset = formRef.current.offsetTop - 160;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleButtonClick = (page) => {
    navigate(page);
  };

  return (
    <div>
      <div className='hero-container'>
        <h2 style={{ marginTop: 20, marginBottom: 15, color: 'white', WebkitTextStroke: '1.5px black' }}>
          Addressing all your software needs
        </h2>
        <h3
          style={{
            marginBottom: 0,
            color: '#FCD505',
            WebkitTextStroke: '0.1px black',
            fontFamily: 'cursive',
            fontStyle: 'italic',
            fontSize: '2rem',
            textAlign: 'center',
          }}
        >
          from code to masterpiece
        </h3>
        <div className='category-section'>
          <div className='category-buttons'>
            <ServicesButton
              onClick={() => handleButtonClick('/services/data-analytics')}
              className='category-btn'
              style={{ padding: '15px 25px', fontSize: '1.2rem', width: '220px' }}
            >
              <FaChartBar style={{ color: 'black' }} className='category-icon' />
              <h3 style={{ color: 'black' }}>Data Vis & Analytics</h3>
            </ServicesButton>

            <ServicesButton
              onClick={() => handleButtonClick('/services/consulting')}
              className='category-btn'
              buttonStyle='btn--secondary'
              style={{ padding: '15px 25px', fontSize: '1.2rem', width: '220px' }}
            >
              <FaComments style={{ color: 'black' }} className='category-icon' />
              <h3 style={{ color: 'black' }}>Consulting Services</h3>
            </ServicesButton>

            <ServicesButton
              onClick={() => handleButtonClick('/services/project-management')}
              className='category-btn'
              buttonStyle='btn--secondary'
              style={{ padding: '15px 25px', fontSize: '1.2rem', width: '220px' }}
            >
              <FaClipboardList style={{ color: 'black' }} className='category-icon' />
              <h3 style={{ color: 'black' }}>Project Management</h3>
            </ServicesButton>
          </div>

          <div className='category-buttons'>
            <ServicesButton
              onClick={() => handleButtonClick('/services/energy-services')}
              className='category-btn'
              buttonStyle='btn--secondary'
              style={{ padding: '15px 25px', fontSize: '1.2rem', width: '220px' }}
            >
              <FaFire style={{ color: 'black' }} className='category-icon' />
              <h3 style={{ color: 'black' }}>Energy</h3>
            </ServicesButton>

            <ServicesButton
              onClick={() => handleButtonClick('/services/construction')}
              className='category-btn'
              buttonStyle='btn--secondary'
              style={{ padding: '15px 25px', fontSize: '1.2rem', width: '220px' }}
            >
              <MdConstruction style={{ color: 'black' }} className='category-icon' />
              <h3 style={{ color: 'black' }}>Construction</h3>
            </ServicesButton>

            <ServicesButton
              onClick={() => handleButtonClick('/services/property-management')}
              className='category-btn'
              buttonStyle='btn--secondary'
              style={{ padding: '15px 25px', fontSize: '1.2rem', width: '220px' }}
            >
              <FaBuilding style={{ color: 'black' }} className='category-icon' />
              <h3 style={{ color: 'black' }}>Property Mgmt</h3>
            </ServicesButton>
          </div>

        </div>
          <div className='hero-btns'>
            <Button
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              style={{ fontSize: '1.3rem' }}
              onClick={handleViewSalesClick}
            >
              - Schedule An Appointment -
            </Button>
          </div>
      </div>

      <Marquee />

      <SmallBusiness />

      <DataAnalytics formRef={formRef} setServiceRequest={setServiceRequest} />
      <ConsultingServices formRef={formRef} setServiceRequest={setServiceRequest} />
      <ProjectManagement formRef={formRef} setServiceRequest={setServiceRequest} />
      <EnergyServices formRef={formRef} setServiceRequest={setServiceRequest} />
      <ConstructionServices formRef={formRef} setServiceRequest={setServiceRequest} />
      <PropertyManagement formRef={formRef} setServiceRequest={setServiceRequest} />
      <Cards servicesRef={servicesRef} formRef={formRef} serviceRequest={serviceRequest} />
    </div>
  );
}

export default HeroSection;
